<template>
  <div class="flex flex-col items-center gap-4 w-full">
    <div
      class="flex flex-col items-center justify-center w-full h-64 sm:h-80 font-bold text-20 tracking-tight"
    >
      <slot name="title"></slot>
    </div>

    <div class="flex flex-col items-center gap-4 text-center w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style></style>
