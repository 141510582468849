<template>
  <div
    id="pricing"
    class="relative flex flex-col items-center w-full px-20 sm:px-40 lg:px-0"
  >
    <div
      class="relative flex flex-col gap-24 sm:gap-8 w-full max-w-[40rem] md:max-w-[calc(96vw)] xl:max-w-[1480px] h-full"
    >
      <div
        class="flex items-center gap-12 rounded-full px-24 py-12 sm:py-20 bg-dark text-beige-light w-fit"
      >
        <SharedSlotsAvailablePing />
      </div>

      <div class="sm:mt-24 lg:mt-32 mb-32 sm:mb-0">
        <h1 v-html="$t('pricing.title')"></h1>

        <div class="max-w-[360px]">
          {{ $t("pricing.description") }}
        </div>
      </div>

      <div
        class="flex flex-col items-center gap-24 sm:gap-8 w-full mt-80 sm:mt-128 lg:mt-160"
      >
        <div class="flex flex-col lg:flex-row gap-24 sm:gap-8 w-full">
          <div
            class="hidden shrink-0 lg:flex flex-col items-center gap-24 sm:gap-8 w-full lg:max-w-[385px]"
          >
            <PagesLandingpageCustomDevelopmentCta
              :pkg-name="websitePackages[chosenPkg].name"
              class="flex grow"
            />
          </div>

          <div
            class="flex flex-col sm:items-center lg:items-start gap-24 sm:gap-8 w-full"
          >
            <div
              class="grow relative flex flex-col rounded-xl px-16 py-24 sm:p-32 lg:p-40 w-full bg-white"
            >
              <div
                class="absolute bottom-[100%] left-0 flex flex-col items-center rounded-[24px] sm:rounded-[28px] md:rounded-[32px] w-full sm:w-fit sm:h-fit mb-8"
              >
                <div class="text-16 sm:text-[18px] whitespace-nowrap mb-8">
                  {{ $t("concept.comparison.page_count") }}
                </div>

                <div
                  class="grid grid-cols-3 gap-4 p-2 bg-dark text-white rounded-full w-full select-none"
                >
                  <div
                    v-for="(pkg, i) in websitePackages"
                    :key="pkg.name"
                    class="relative flex justify-center items-center px-24 sm:px-40 py-8 cursor-pointer"
                    :class="
                      i === chosenPkg && 'bg-white text-dark rounded-full'
                    "
                    @click="chosenPkg = i"
                  >
                    {{ pkg.pages }}
                  </div>
                </div>
              </div>

              <div class="flex justify-between w-full px-8 sm:px-0">
                <div
                  class="relative flex flex-col rounded-[24px] sm:rounded-[28px] md:rounded-[32px] w-full sm:w-fit sm:h-fit sm:mb-40"
                >
                  <div>
                    <h2 class="font-special capitalize mb-0 sm:mb-24">
                      {{ websitePackages[chosenPkg].name }}
                    </h2>
                    <div class="hidden sm:block max-w-[400px]">
                      {{ websitePackages[chosenPkg].description }}
                    </div>
                  </div>
                </div>

                <div>
                  <h2 class="mb-0">
                    {{ websitePackages[chosenPkg].price
                    }}<span class="text-20">/mo</span>
                    <div
                      class="flex justify-end text-16 sm:text-20 font-normal tracking-wide"
                    >
                      {{ $t("pricing.packages.tax") }}
                    </div>
                  </h2>
                </div>
              </div>

              <div class="sm:hidden my-16 px-8 sm:px-0">
                {{ websitePackages[chosenPkg].description }}
              </div>

              <div
                class="grow flex flex-col gap-24 md:gap-40 justify-between w-full"
              >
                <div class="flex flex-col md:grid md:grid-cols-2 w-full gap-8">
                  <div
                    v-for="(feature, i) in websitePackages[chosenPkg].features"
                    :key="feature.label"
                    class="p-16 sm:p-24 xl:p-40 rounded-md sm:rounded-lg bg-[#00000005]"
                  >
                    <div class="text-14 mb-4 uppercase">
                      {{ feature.label }}

                      <SharedLink
                        v-if="feature.label.toLowerCase().includes('design')"
                        url="/"
                        hash="#faq-design-updates"
                        :inline="true"
                        class="underline mt-8 w-fit"
                      >
                        <IconsIconHelp class="h-20 w-20 inline mb-4" />
                      </SharedLink>
                    </div>

                    <div
                      v-if="feature.description.length"
                      class="flex flex-col gap-8 mt-8"
                    >
                      <div
                        v-for="(item, j) in feature.description"
                        class="flex gap-8 lg:max-w-[360px]"
                      >
                        <IconsIconCheck
                          class="shrink-0 h-16 w-16 sm:h-20 sm:w-20 mt-4"
                        />

                        <div>
                          {{ item }}

                          <SharedLink
                            v-if="
                              feature.label.toLowerCase().includes('web') &&
                              j === 0
                            "
                            url="/"
                            hash="#faq-what-is-a-page-design"
                            :inline="true"
                          >
                            <IconsIconHelp class="h-20 w-20 inline mb-4" />
                          </SharedLink>
                        </div>
                      </div>

                      <div
                        v-if="
                          feature.label.toLowerCase().includes('content') &&
                          websitePackages[chosenPkg].name === 'starter'
                        "
                        class="hidden md:block h-[54px]"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="flex justify-center sm:justify-end items-center gap-20 w-full"
                >
                  <SharedMeetingLink class="w-full sm:w-fit">
                    <UiButton class="w-full">{{
                      websitePackages[chosenPkg].cta
                    }}</UiButton>
                  </SharedMeetingLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-7 gap-8 w-full">
          <div class="col-span-3 hidden lg:grid grid-rows-5 gap-8">
            <div class="row-span-3 flex flex-col gap-8 w-full h-full">
              <UiClickBox
                url="/contact"
                :label="$t('pricing.demo.title')"
                :description="$t('pricing.demo.description')"
                class="h-full bg-[#E6CD90]"
              />

              <UiClickBox
                url="/referrals"
                :label="$t('pricing.200.title')"
                :description="$t('pricing.200.description')"
                class="h-full bg-[#E6CD90]"
              />
            </div>

            <div class="relative row-span-2 flex flex-col gap-8 w-full h-full">
              <img
                :src="customDevelopment"
                class="absolute top-0 left-0 right-0 bottom-0 h-full w-full object-cover object-right-top rounded-xl"
              />
            </div>
          </div>

          <div
            class="lg:col-span-4 lg:h-full rounded-xl bg-[#E6CD90] p-24 sm:p-32 xl:p-40 w-full"
          >
            <div class="flex justify-between sm:gap-24 w-full">
              <div>
                <div
                  class="font-special text-24 sm:text-[40px] lg:text-[56px] mb-16 lg:mb-24 leading-[1.1]"
                >
                  Micro <br class="sm:hidden" />
                  Application
                </div>

                <div class="hidden sm:block max-w-[400px] lg:max-w-[580px]">
                  {{ $t("pricing.addon.description") }}
                </div>
              </div>

              <div class="shrink-0">
                <div class="flex flex-col items-end relative font-special">
                  <div>
                    <span class="text-20 pr-8">{{ $t("x.from") }}</span>

                    <span
                      class="text-24 sm:text-[40px] lg:text-[56px] leading-[1.1]"
                      >99€</span
                    ><span class="text-20">/mo</span>
                  </div>
                  <div class="text-16 sm:text-20 font-special">
                    {{ $t("pricing.packages.tax") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:hidden">
              {{ $t("pricing.addon.description") }}
            </div>

            <div
              class="flex flex-col gap-24 sm:gap-40 w-full mt-16 sm:mt-24 lg:mt-40"
            >
              <div class="flex flex-col gap-8 mt-8">
                <div
                  v-for="feature in $tm('pricing.addon.features')"
                  class="flex gap-8"
                >
                  <IconsIconCheck
                    class="h-16 sm:h-20 w-16 sm:w-20 shrink-0 mt-4"
                  />

                  <div>{{ feature }}</div>
                </div>
              </div>

              <div class="flex justify-end w-full">
                <SharedMeetingLink url="/contact" class="w-full sm:w-fit">
                  <UiButton size="small" class="w-full">
                    {{ $t("pricing.addon.cta") }}
                  </UiButton>
                </SharedMeetingLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:hidden flex flex-col sm:flex-row gap-24 sm:gap-8 w-full">
        <PagesLandingpageCustomDevelopmentCta
          :pkg-name="websitePackages[chosenPkg].name"
          class="min-h-[420px]"
        />

        <div class="shrink-0 flex flex-col sm:grid sm:grid-rows-2 gap-8">
          <UiClickBox
            url="/contact"
            :label="$t('pricing.demo.title')"
            :description="$t('pricing.demo.description')"
            class="bg-[#E6CD90]"
          />

          <UiClickBox
            url="/referrals"
            :label="$t('pricing.200.title')"
            :description="$t('pricing.200.description')"
            class="bg-[#E6CD90]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import customDevelopment from "~/assets/img/landingpage/custom-development.webp";
import { getWebsitePackages } from "~/lib/pricing/pricing-packages";

const chosenPkg = ref(1);
const websitePackages = computed(() => getWebsitePackages());
</script>

<style lang="scss">
#pricing {
  @apply text-16 sm:text-[18px];
}
</style>
