<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 18.0219C13 18.5742 12.5523 19.0219 12 19.0219C11.4477 19.0219 11 18.5742 11 18.0219C11 17.4696 11.4477 17.0219 12 17.0219C12.5523 17.0219 13 17.4696 13 18.0219Z"
      fill="currentColor"
    />
    <path
      d="M9.5 9.40366C9.5 8.0311 10.4767 7.00366 12 7.00366C13.5233 7.00366 14.5 8.0311 14.5 9.40366C14.5 10.4022 13.9818 10.9128 13.1186 11.6157C13.0666 11.658 13.0122 11.7017 12.9557 11.747C12.1679 12.3791 11 13.3162 11 15.0037C11 15.5559 11.4477 16.0037 12 16.0037C12.5523 16.0037 13 15.5559 13 15.0037C13 14.312 13.4088 13.9586 14.3814 13.1666C15.2682 12.4445 16.5 11.4051 16.5 9.40366C16.5 6.89587 14.597 5.00366 12 5.00366C9.403 5.00366 7.5 6.89587 7.5 9.40366C7.5 9.95595 7.94772 10.4037 8.5 10.4037C9.05229 10.4037 9.5 9.95595 9.5 9.40366Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 23.0037C18.0751 23.0037 23 18.0788 23 12.0037C23 5.92853 18.0751 1.00366 12 1.00366C5.92487 1.00366 1 5.92853 1 12.0037C1 18.0788 5.92487 23.0037 12 23.0037ZM12 21.0037C16.9706 21.0037 21 16.9742 21 12.0037C21 7.0331 16.9706 3.00366 12 3.00366C7.02944 3.00366 3 7.0331 3 12.0037C3 16.9742 7.02944 21.0037 12 21.0037Z"
      fill="currentColor"
    />
  </svg>
</template>
