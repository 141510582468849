<template>
  <div
    id="summary"
    class="flex flex-col items-center w-full pointer-events-none"
  >
    <ClientOnly>
      <SwiperContainer
        :initial-slide="4"
        :slides-per-view="'auto'"
        :centered-slides="true"
        :modules="[Autoplay, FreeMode]"
        :autoplay="{
          delay: 0,
          pauseOnMouseEnter: false,
          disableOnInteraction: false,
        }"
        :allow-touch-move="false"
        :free-mode="true"
        :speed="8000"
        :loop="true"
        class="w-full max-w-full"
        @swiper="(swiper) => (swiperInstance = swiper)"
        @slideChange="(swiper) => (currentSlideIndex = swiper.realIndex)"
      >
        <SwiperSlide
          v-for="(benefit, i) in [
            ...benefits,
            ...benefits,
            ...benefits,
            ...benefits,
          ]"
          :key="i"
        >
          <div
            class="uppercase text-14 lg:text-[18px] bg-[#e3d2be] rounded-full px-12 sm:px-20 py-4 w-fit select-none"
          >
            {{ benefit.label }}
          </div>
        </SwiperSlide>
      </SwiperContainer>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import Swiper from "swiper";
import { Autoplay, FreeMode } from "swiper/modules";
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/vue";

const swiperInstance = ref<Swiper | undefined>(undefined);
const currentSlideIndex = ref(1);

const { tm } = useNuxtApp().$i18n;
const benefits = computed(() => [
  {
    label: tm("summary.benefits")[0],
  },
  {
    label: tm("summary.benefits")[1],
  },
  {
    label: tm("summary.benefits")[2],
  },
  {
    label: tm("summary.benefits")[3],
  },
  {
    label: tm("summary.benefits")[4],
  },
  {
    label: tm("summary.benefits")[5],
  },
]);
</script>

<style lang="scss" scoped>
.swiper {
  @apply mx-0;
}
.swiper-slide {
  @apply w-fit h-fit mr-4 sm:mr-8;
}
</style>
