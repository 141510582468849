<template>
  <div
    ref="section"
    class="flex flex-col w-fit bg-[#e3d2be] rounded-xl transition-all hover:bg-[#00000006]"
  >
    <div class="flex gap-16 cursor-pointer p-24" @click="expand()">
      <IconsIconChevron
        class="shrink-0 h-20 w-20 transition-transform mt-2 sm:mt-2"
        :class="selected ? '-rotate-90' : 'rotate-90'"
      />

      <div class="font-bold select-none">{{ label }}</div>
    </div>

    <div
      class="flex flex-col gap-16 ml-[36px] px-24 pb-24"
      :class="selected ? 'flex' : 'hidden'"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label: string;
  selectedSection: string;
  id?: string;
}>();
const emit = defineEmits(["update:selectedSection"]);

const _id = ref(props.id || crypto.randomUUID());
const section = ref<HTMLDivElement | null>(null);
const selected = computed(() => _id.value === props.selectedSection);
watchEffect(() => {
  if (!selected.value) return;
  if (!section.value) return;

  section.value.scrollIntoView({ block: "center" });
});

function expand() {
  emit("update:selectedSection", selected.value ? "" : _id.value);
}
</script>
