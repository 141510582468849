<template>
  <svg viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M55.1753 10.1076C58.9879 4.69536 67.0121 4.69536 70.8247 10.1076V10.1076C73.4879 13.8881 78.4732 15.2239 82.6698 13.2815V13.2815C88.6778 10.5007 95.6269 14.5127 96.2226 21.1062V21.1062C96.6387 25.7118 100.288 29.3613 104.894 29.7774V29.7774C111.487 30.3731 115.499 37.3222 112.719 43.3302V43.3302C110.776 47.5269 112.112 52.5121 115.892 55.1753V55.1753C121.305 58.9879 121.305 67.0121 115.892 70.8247V70.8247C112.112 73.4879 110.776 78.4732 112.719 82.6698V82.6698C115.499 88.6778 111.487 95.6269 104.894 96.2226V96.2226C100.288 96.6387 96.6387 100.288 96.2226 104.894V104.894C95.6269 111.487 88.6778 115.499 82.6698 112.719V112.719C78.4732 110.776 73.4879 112.112 70.8247 115.892V115.892C67.0121 121.305 58.9879 121.305 55.1753 115.892V115.892C52.5121 112.112 47.5269 110.776 43.3302 112.719V112.719C37.3222 115.499 30.3731 111.487 29.7774 104.894V104.894C29.3613 100.288 25.7118 96.6387 21.1062 96.2226V96.2226C14.5127 95.6269 10.5007 88.6778 13.2815 82.6698V82.6698C15.2239 78.4732 13.8881 73.4879 10.1076 70.8247V70.8247C4.69536 67.0121 4.69536 58.9879 10.1076 55.1753V55.1753C13.8881 52.5121 15.2239 47.5269 13.2815 43.3302V43.3302C10.5007 37.3222 14.5127 30.3731 21.1062 29.7774V29.7774C25.7118 29.3613 29.3613 25.7118 29.7774 21.1062V21.1062C30.3731 14.5127 37.3222 10.5007 43.3302 13.2815V13.2815C47.5269 15.2239 52.5121 13.8881 55.1753 10.1076V10.1076Z"
      fill="#FA533D"
    />
    <path
      d="M52.7227 8.37991C50.8943 10.9755 47.4716 11.8926 44.5903 10.559C36.6992 6.90654 27.572 12.1762 26.7896 20.8363C26.5039 23.9983 23.9983 26.5039 20.8363 26.7896C12.1762 27.572 6.90654 36.6992 10.559 44.5903C11.8926 47.4716 10.9755 50.8943 8.37991 52.7227C1.27126 57.7304 1.27126 68.2696 8.37991 73.2773C10.9755 75.1057 11.8926 78.5284 10.559 81.4097C6.90654 89.3008 12.1762 98.428 20.8363 99.2104C23.9983 99.4961 26.5039 102.002 26.7896 105.164C27.572 113.824 36.6992 119.093 44.5903 115.441C47.4716 114.107 50.8943 115.025 52.7227 117.62C57.7304 124.729 68.2696 124.729 73.2773 117.62C75.1057 115.025 78.5284 114.107 81.4097 115.441C89.3008 119.093 98.428 113.824 99.2104 105.164C99.4961 102.002 102.002 99.4961 105.164 99.2104C113.824 98.428 119.093 89.3008 115.441 81.4097C114.107 78.5284 115.025 75.1057 117.62 73.2773C124.729 68.2696 124.729 57.7304 117.62 52.7227C115.025 50.8943 114.107 47.4716 115.441 44.5903C119.093 36.6992 113.824 27.572 105.164 26.7896C102.002 26.5039 99.4961 23.9983 99.2104 20.8363C98.428 12.1762 89.3008 6.90654 81.4097 10.559C78.5284 11.8926 75.1057 10.9755 73.2773 8.37991C68.2696 1.27126 57.7304 1.27126 52.7227 8.37991Z"
      stroke="#E24230"
      stroke-opacity="0.2"
      stroke-width="6"
    />
  </svg>
</template>
