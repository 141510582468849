type PackageName = "starter" | "premium" | "unlimited";

export function getPackageInfo(name: PackageName) {
  const { t, tm } = useNuxtApp().$i18n;

  return {
    name: t(`pricing.packages.${name}.name`),
    description: t(`pricing.packages.${name}.description`),
    features: tm(`pricing.packages.${name}.features`) as any,
    cta: t(`pricing.packages.${name}.cta`),
    ...(name === "starter"
      ? {
          pageDesigns: "1-3",
          pages: "1-5",
          price: "€199",
        }
      : name === "premium"
      ? {
          pageDesigns: "1-10",
          pages: "10-20",
          price: "€999",
        }
      : {
          pageDesigns: "10+",
          pages: "20+",
          price: "€3999",
        }),
  };
}

export function getWebsitePackages() {
  return (["starter", "premium", "unlimited"] as PackageName[]).map((name) =>
    getPackageInfo(name)
  );
}
