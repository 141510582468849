<template>
  <div id="services" class="flex flex-col items-center w-full">
    <div class="flex justify-center w-full px-20 sm:px-40 xl:px-0">
      <div class="w-full md:max-w-[calc(96vw)] xl:max-w-[1480px]">
        <h1>{{ $t("services.title") }}</h1>
      </div>
    </div>

    <PagesLandingpageSummary class="pb-4 md:pb-8" />

    <div
      class="grow flex justify-center bg-[#e3d2be] w-full sm:h-[65vh] md:h-[85vh] pt-20 pb-20 sm:py-0 sm:px-40 xl:px-0"
    >
      <div
        class="flex flex-col sm:flex-row gap-40 lg:gap-128 pt-24 pb-24 sm:py-0 rounded-xl w-full md:max-w-[calc(96vw)] xl:max-w-[1480px] h-full"
      >
        <div
          class="relative shrink-0 hidden sm:flex max-h-full select-none pointer-events-none"
        >
          <div
            class="md:grid md:grid-cols-2 md:gap-80 max-h-full overflow-hidden"
          >
            <ClientOnly>
              <SwiperContainer
                :initial-slide="3"
                :centered-slides="true"
                :slides-per-view="'auto'"
                :space-between="80"
                :grab-cursor="false"
                :allow-touch-move="false"
                direction="vertical"
                :autoplay="{
                  delay: 0,
                  pauseOnMouseEnter: false,
                  disableOnInteraction: false,
                  reverseDirection: true,
                }"
                :free-mode="true"
                :speed="8000"
                :loop="true"
                class="w-full max-w-full max-h-full"
                :modules="[Controller, Autoplay, FreeMode]"
                @swiper="(swiper) => (desktopSwiperInstance = swiper)"
                @slideChange="
                  (swiper) => (currentSlideIndex = swiper.realIndex)
                "
              >
                <SwiperSlide
                  v-for="(service, i) in [
                    ...services,
                    ...services,
                    ...services,
                  ]"
                  :key="i"
                >
                  <div class="flex flex-col rounded-lg lg:w-full">
                    <h3 class="mb-8">
                      {{ service.label }}
                    </h3>
                    <img
                      :src="service.img"
                      class="object-cover aspect-video w-full rounded-lg lg:rounded-xl object-center"
                    />
                  </div>
                </SwiperSlide>
              </SwiperContainer>
            </ClientOnly>

            <ClientOnly>
              <SwiperContainer
                :initial-slide="0"
                :centered-slides="true"
                :slides-per-view="'auto'"
                :space-between="80"
                :grab-cursor="false"
                :allow-touch-move="false"
                direction="vertical"
                :autoplay="{
                  delay: 0,
                  pauseOnMouseEnter: false,
                  disableOnInteraction: false,
                }"
                :free-mode="true"
                :speed="8000"
                :loop="true"
                :modules="[Controller, Autoplay, FreeMode]"
                class="hidden md:block w-full max-w-full max-h-full"
                @swiper="(swiper) => (desktopSwiperInstance = swiper)"
                @slideChange="
                  (swiper) => (currentSlideIndex = swiper.realIndex)
                "
              >
                <SwiperSlide
                  v-for="(service, i) in [
                    ...shuffledServices,
                    ...shuffledServices,
                    ...shuffledServices,
                  ]"
                  :key="i"
                >
                  <div class="flex flex-col rounded-lg lg:w-full">
                    <h3 class="mb-8">
                      {{ service.label }}
                    </h3>
                    <img
                      :src="service.img"
                      class="object-cover aspect-video w-full rounded-lg lg:rounded-xl object-center"
                    />
                  </div>
                </SwiperSlide>
              </SwiperContainer>
            </ClientOnly>
          </div>
        </div>

        <div class="shrink-0 sm:hidden overflow-hidden">
          <ClientOnly>
            <SwiperContainer
              :initial-slide="2"
              :centered-slides="true"
              :slides-per-view="'auto'"
              :space-between="16"
              :grab-cursor="true"
              :allow-touch-move="true"
              :autoplay="{
                delay: 0,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
                reverseDirection: true,
              }"
              :free-mode="true"
              :speed="8000"
              :loop="true"
              :modules="[Controller, Autoplay, FreeMode]"
              class="w-full max-w-full max-h-full"
              @swiper="(swiper) => (mobileSwiperInstance = swiper)"
              @slideChange="(swiper) => (currentSlideIndex = swiper.realIndex)"
            >
              <SwiperSlide
                v-for="(service, i) in [...services, ...services, ...services]"
                :key="i"
              >
                <div class="flex flex-col rounded-lg lg:w-full">
                  <h3 class="mb-8">
                    {{ service.label }}
                  </h3>
                  <img
                    :src="service.img"
                    class="object-cover aspect-video w-full rounded-lg object-center"
                  />
                </div>
              </SwiperSlide>
            </SwiperContainer>
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Swiper from "swiper";
import { Autoplay, Controller, FreeMode } from "swiper/modules";
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/vue";

import serviceAi from "~/assets/img/landingpage/services-ai.webp";
import serviceAnalytics from "~/assets/img/landingpage/services-analytics.webp";
import serviceDesign from "~/assets/img/landingpage/services-design.webp";
import serviceSaas from "~/assets/img/landingpage/services-saas.webp";
import serviceWebsites from "~/assets/img/landingpage/services-websites.webp";

const desktopSwiperInstance = ref<Swiper | undefined>(undefined);
const mobileSwiperInstance = ref<Swiper | undefined>(undefined);
onMounted(() => {
  if (!desktopSwiperInstance.value || !mobileSwiperInstance.value) return;

  desktopSwiperInstance.value.controller.control = mobileSwiperInstance.value;
  mobileSwiperInstance.value.controller.control = desktopSwiperInstance.value;
});

const currentSlideIndex = ref(0);

const { t } = useNuxtApp().$i18n;
const services = computed(() => [
  {
    label: t("services.services.saas"),
    img: serviceSaas,
  },
  {
    label: t("services.services.analytics"),
    img: serviceAnalytics,
  },
  {
    label: t("services.services.websites"),
    img: serviceWebsites,
  },
  {
    label: t("services.services.ai"),
    img: serviceAi,
  },
  {
    label: t("services.services.design"),
    img: serviceDesign,
  },
]);
const shuffledServices = computed(() => [
  services.value[1],
  services.value[3],
  services.value[0],
  services.value[2],
  services.value[4],
]);
</script>

<style lang="scss" scoped>
.swiper-slide {
  @apply w-[80vw] md:max-w-[400px] lg:max-w-[500px] xl:max-w-[700px] h-fit;
}
</style>
