<template>
  <div class="flex flex-col items-center w-full md:w-auto">
    <div
      class="relative flex flex-col items-center rounded-[24px] sm:rounded-[28px] md:rounded-[32px] w-full sm:w-fit sm:h-fit mb-40"
    >
      <div class="text-16 whitespace-nowrap mb-8">
        {{ $t("concept.comparison.page_count") }}
      </div>

      <div
        class="grid grid-cols-3 gap-4 p-2 bg-dark text-white rounded-full w-full select-none"
      >
        <div
          v-for="(pages, i) in packages.map((pkg) => pkg.pages)"
          :key="i"
          class="relative flex justify-center items-center px-24 sm:px-40 py-8 cursor-pointer"
          :class="i === currentScenario && 'bg-white text-dark rounded-full'"
          @click="currentScenario = i"
        >
          {{ pages }}
        </div>
      </div>
    </div>

    <div class="relative flex w-full md:w-auto gap-8 sm:gap-20 select-none">
      <div class="shrink-0 mt-[68px] sm:mt-[82px] text-16 text-right font-bold">
        <div class="flex flex-col gap-4">
          <div
            v-for="label in $tm('concept.comparison.labels')"
            :key="label"
            class="flex items-center h-[42px] text-12 sm:text-14 py-8 border-b-2 border-transparent"
          >
            {{ label }}
          </div>
        </div>
      </div>

      <div class="md:w-auto overflow-y-hidden text-14 pb-48 md:pb-0">
        <SwiperContainer
          :initial-slide="currentSlideIndex"
          :slides-per-view="'auto'"
          :space-between="0"
          :grab-cursor="false"
          class="w-full max-w-full rounded-lg"
          @swiper="(swiper) => (swiperInstance = swiper)"
          @slideChange="(swiper) => (currentSlideIndex = swiper.realIndex)"
        >
          <SwiperSlide>
            <PagesLandingpageComparisonTableColumn
              class="bg-[#968b7417] rounded-lg"
            >
              <template #title>
                <IconsIconLogo class="h-24 sm:h-[28px] lg:h-32" />
              </template>

              <PagesLandingpageComparisonTableCell
                v-for="(data,i) in [packages.map(pkg => pkg.price).join('/'), ...($tm('concept.comparison.hyperfocused.values') as string[])]"
                :value="i === 0 ? `${formatValue(data)}/mo` : formatValue(data)"
              />
            </PagesLandingpageComparisonTableColumn>
          </SwiperSlide>

          <SwiperSlide v-for="column in ['freelancer', 'agency', 'top_agency']">
            <PagesLandingpageComparisonTableColumn>
              <template #title>
                {{ $t(`concept.comparison.${column}.title`) }}
              </template>

              <PagesLandingpageComparisonTableCell
                v-for="(data,i) in ($tm(`concept.comparison.${column}.values`) as string[])"
                :value="formatValue(data)"
              />
            </PagesLandingpageComparisonTableColumn>
          </SwiperSlide>
        </SwiperContainer>
      </div>

      <div
        class="md:hidden absolute bottom-0 left-20 flex justify-center items-center h-32 w-32 sm:h-40 sm:w-40 bg-beige border-2 border-dark rounded-full z-[2] cursor-pointer"
        :class="currentSlideIndex === 0 && 'transition-opacity opacity-25'"
        @click="swiperInstance?.slidePrev()"
      >
        <IconsIconChevron
          class="shrink-0 h-24 w-24 sm:h-[28px] sm:w-[28px] rotate-180"
        />
      </div>
      <div
        class="md:hidden absolute bottom-0 right-20 flex justify-center items-center h-32 w-32 sm:h-40 sm:w-40 bg-beige border-2 border-dark rounded-full z-[2] cursor-pointer"
        :class="currentSlideIndex === 3 && 'transition-opacity opacity-25'"
        @click="swiperInstance?.slideNext()"
      >
        <IconsIconChevron class="shrink-0 h-24 w-24 sm:h-[28px] sm:w-[28px]" />
      </div>
    </div>

    <div class="-mt-[28px] sm:pt-40 text-14 text-[#2b1c15d9]">
      <IconsIconClose class="h-16 w-16 inline text-[#2b1c155c]" />
      {{ $t("concept.comparison.legend") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import Swiper from "swiper";
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/vue";
import { getWebsitePackages } from "~/lib/pricing/pricing-packages";

const currentScenario = ref(1);

const swiperInstance = ref<Swiper | undefined>(undefined);
const currentSlideIndex = ref(0);

const packages = computed(() => getWebsitePackages());

function formatValue(value: string) {
  if (!value.includes("/")) return value;

  return value.split("/")[currentScenario.value];
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  @apply w-[200px];
}
</style>
