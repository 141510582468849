<template>
  <div class="flex items-center gap-12 w-fit fade-in-move-up-4">
    <div class="relative h-12 w-12">
      <div class="bg-[#b12918] h-full w-full rounded-full"></div>
      <div
        class="absolute top-0 left-0 bottom-0 right-0 bg-[#b12918] h-full w-full rounded-full pingping"
      ></div>
    </div>

    <div class="text-12 sm:text-14">
      {{ $t("hero.available") }}
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style></style>
