<template>
  <div class="flex flex-col items-center w-full pb-80">
    <div class="relative flex flex-col items-center w-full overflow-hidden">
      <div
        class="flex flex-col items-center md:items-start w-full md:w-auto max-w-[40rem] sm:max-w-[1440px] xl:max-w-[1940px] pt-24 sm:pt-96 md:pt-160 px-20 sm:px-40"
      >
        <SharedSlotsAvailablePing
          class="md:hidden fade-in-move-up-4 mb-80 sm:mb-40"
        />

        <div
          class="flex flex-col md:flex-row items-center md:items-end gap-24 md:gap-64 lg:gap-80 mb-24 sm:mb-32 lg:mb-40"
        >
          <h1
            v-html="$t('hero.title')"
            class="text-center md:text-left text-[34px] sm:text-[64px] md:text-[56px] lg:text-[60px] xl:text-[100px] lg:leading-[1] lg:tracking-tight font-special leading-[1.1] fade-in-move-up mb-0"
          ></h1>

          <div
            class="max-w-[320px] lg:max-w-[420px] fade-in-move-up-2 xl:text-20 md:mb-8 md:pl-24 md:border-l-2 md:border-[#2b1c15bf] text-center md:text-left"
          >
            {{ $t("hero.description") }}
          </div>
        </div>

        <div class="flex items-center gap-24 w-fit">
          <SharedLink url="/" hash="#pricing" class="fade-in-move-up-3">
            <UiButton class="hidden sm:flex">
              {{ $t("hero.cta") }}
            </UiButton>
            <UiButton size="small" class="sm:hidden">
              {{ $t("hero.cta") }}
            </UiButton>
          </SharedLink>

          <SharedSlotsAvailablePing class="hidden md:flex fade-in-move-up-4" />
        </div>
      </div>

      <PagesLandingpageReferences class="mb-64 lg:mb-80" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.animated-bg {
  animation: fadebg 3s ease-in-out forwards;
}

@keyframes fadebg {
  0% {
    opacity: 40%;
    transform: translateY(-20px);
  }
  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}
</style>
